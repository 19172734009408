import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import Colors from '../../../themes/Colors';
import Breakpoints from '../../../themes/Breakpoints';
import Metrics from '../../../themes/Metrics';

import selectChevron from '../../../images/icons/selectChevron.svg';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: auto;
  text-align: center;
  margin: 0 auto ${Metrics.smallSpacer};

  @media (min-width: ${Breakpoints.medium}) {
    flex-direction: row;
    padding: ${Metrics.tinySpacer};
    color: ${Colors.darkBlue};
    background-color: ${Colors.white};
    border-radius: 5px;
  }

  @media (min-width: ${Breakpoints.medium}) {
    &:after {
      content: '';
      display: block;
      width: 1.5rem;
      height: 100%;
      margin-left: 1em;
      background-image: url(${selectChevron});
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
`;

const Icon = styled.div`
  display: block;
  margin: 0 auto;
  width: 3em;
  flex-shrink: 0;
  flex-grow: 0;

  @media (min-width: ${Breakpoints.medium}) {
    width: 2em;
    color: ${Colors.darkBlue};
    margin: 0 ${Metrics.tinySpacer} 0 0;
  }
`;

const Question = styled.div`
  display: block;
  flex-grow: 1;

  p.question {
    text-align: center;
    margin: ${Metrics.tinySpacer} 0;
  }

  p.answer {
    text-align: left;
    display: none;
  }

  select {
    width: 100%;
    padding: 4px 2px;
  }

  @media (min-width: ${Breakpoints.medium}) {
    margin: 0;

    p.question {
      text-align: left;
      margin: 0 0 10px;
      color: ${Colors.red};
    }

    p.answer {
      display: block;
    }

    select {
      padding: 0;
      margin: 0;
      border-style: none;
      background-color: ${Colors.white};
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`;

const Selector = ({ className, icon, question, min = 1, max = 10, step = 1, defaultValue, suffixes, changeHandler }) => {
  return <Wrapper className={className}>

    <Icon image={icon}>
      {parse(icon)}
    </Icon>

    <Question>

      <p className="question">{question}</p>
      <p className="answer">{defaultValue} {suffixes && ' ' + (defaultValue === 1 ? suffixes.singular : suffixes.plural)}</p>

      {/* eslint-disable-next-line jsx-a11y/no-onchange */}
      <select onChange={changeHandler} defaultValue={defaultValue}>
        {[...Array(Math.floor((max - min) / step) + 1)].map((_, index) => {
          const val = index * step + min;
          return (
            <option key={index} value={val}>
              {val} {suffixes && ' ' + (val === 1 ? suffixes.singular : suffixes.plural)}
            </option>
          );
        }
        )}
      </select>

    </Question>

  </Wrapper>;
};

export default Selector;
